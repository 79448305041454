import MultiLayerFilter from '@/common/components/MultiLayerFilter';
import { OptionValue } from '@/common/types';
import useTranslation from '@/utils/i18n/useTranslation';
import { useHierarchicalAssets } from '../hooks/useHierarchicalAssets';

type Props = {
  values?: number[];
  onChange: (values?: number[]) => void;
  onOpen?: (isOpen: boolean) => void;
};

export const AssetMultiLayerFilter = ({ values, onChange, onOpen }: Props) => {
  const { t } = useTranslation();
  const { selectAssetItems } = useHierarchicalAssets();

  const onAssetFilterChange = (assetIds?: OptionValue[]) => {
    const ids =
      assetIds && assetIds.length > 0 ? assetIds.map((assetId) => Number(assetId)) : undefined;
    onChange(ids);
  };

  return (
    <MultiLayerFilter
      label={t('pages.asset')}
      values={values}
      options={selectAssetItems}
      onChange={onAssetFilterChange}
      onOpenStatusChange={onOpen}
    />
  );
};
